import React, {
  useRef,
  Fragment,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { jsx, css } from '@emotion/react';
import ReactDOM from 'react-dom';

import { Container } from './container';
import { Background } from './background';

export interface ModalProps {
  isShowing: boolean;
  toggle: () => void;
  padding?: number;
  isDismissable?: boolean;
  children?: React.ReactNode;
}

export const Modal = forwardRef<HTMLDivElement, ModalProps>(
  (
    { isShowing, toggle, children, padding = 1, isDismissable = true },
    forwardReff
  ) => {
    useEffect(() => {
      let body = document.querySelectorAll('BODY')[0] as HTMLElement;
      if (isShowing) {
        body.style.overflowY = 'hidden';
      } else {
        body.style.overflowY = 'auto';
      }
    }, [isShowing]);

    return isShowing
      ? ReactDOM.createPortal(
          <Fragment>
            <Background
              onClick={() => (isDismissable ? toggle() : undefined)}
            />
            <Container
              ref={forwardReff}
              padding={padding}
              toggle={toggle}
              isDismissable={isDismissable}
            >
              {children}
            </Container>
          </Fragment>,
          document.body
        )
      : null;
  }
);
