/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css, useTheme } from '@emotion/react';
import React, { useEffect, useState } from 'react';
import { Input } from '../Input';
import { Select } from '../Select';
import { StyledTimeInput } from './style';

export interface IProps {
  label: string;
  sizer: 'S' | 'M';
  onChange?: (val: string) => void;
  noLabel?: boolean;
  flatMode?: boolean;
  required?: boolean;
}

const customSelect = css`
  .value {
    padding: 8px;
    text-align: center;
  }
`;

export const TimeInput = ({
  label,
  sizer,
  onChange,
  noLabel = false,
  flatMode = false,
  required,
  ...rest
}: IProps & React.InputHTMLAttributes<HTMLInputElement>) => {
  const colors = useTheme();
  const [select, onSelect] = useState('');
  const [value, setValue] = useState('');

  useEffect(() => {
    if ((rest as { value: string }).value === '') {
      setValue('');
      onSelect('');
    } else {
      if (
        (rest as { value: string }).value !== `${value} ${select}` &&
        (rest as { value: string }).value
      ) {
        let values = (rest as { value: string }).value.split(' ');
        setValue(values[0]);
        onSelect(values[1]);
      }
    }
  }, [(rest as { value: string }).value]);

  useEffect(() => {
    if (onChange) {
      let val = `${value} ${select}`;
      if (val != ' ') {
        onChange(val);
      }
    }
  }, [value, select]);

  return (
    <StyledTimeInput style={{ ...rest.style }} theme={{ ...colors }}>
      <Input
        value={value}
        onChange={(e) => setValue(e.target.value)}
        sizer={sizer}
        // style={{ margin: flatMode ? '0' : '16px 0 1px 0' }}
        label={label}
        disableTrailing
        format="time"
        noLabel={noLabel}
        hideError={flatMode}
        required={required}
        style={{ width: '90%' }}
      />
      <Select
        value={select}
        onSelecte={(v: string) => onSelect(v)}
        style={{
          marginLeft: '8px',
          width: '50%',
        }}
        css={customSelect}
        noLabel={!flatMode}
        options={[
          { label: 'AM', value: 'am' },
          { label: 'PM', value: 'pm' },
        ]}
      />
    </StyledTimeInput>
  );
};
