/** @jsxRuntime classic */
/** @jsx jsx */
import React, { Fragment, useMemo, useRef } from 'react';
import { css, jsx } from '@emotion/react';
import { Typography } from '../../../atoms';
import {
  BnIcon,
  Button,
  ContainerWithIcon,
  Modal,
  ModalWindow,
  useModal,
} from '../../../components';
import {
  ETypography,
  ETypographyColor,
  IColors,
  Icons,
} from '../../../types/theme';
import { useColors } from '../../../hooks/useColors';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useTimeFormats } from '../../../hooks/useTimeFormats';
import { sportsIcons } from '../../../types';
import { useWindowSize } from '../../../hooks/useWindowSize';

export const ProgramTypes = {
  0: 'League',
  1: 'Tournament',
  2: 'Class',
  3: 'Clinic',
  4: 'Camp',
  5: 'Lesson',
  6: 'Club',
};

dayjs.extend(customParseFormat);

const containerCss = (colors: IColors) => css`
  display: flex;
  align-items: center;
  svg {
    height: 12px;
    width: 12px;
    color: ${colors.tx_text_teriaty};
    margin-right: 4px;
  }
  .divider {
    margin: 0 12px;
    width: 1px;
    height: 12px;
    background: ${colors.bg_border_seperator};
  }
  .overline {
    margin-right: 4px;
  }

  .body-accented {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const container = css`
  padding: 8px 12px 8px 15px;
  position: relative;
  border-left: 3px solid transparent;
  cursor: pointer;
  &:hover {
    box-shadow: 0px 2px 22px 8px rgba(12, 11, 12, 0.08);
    border-radius: 5px;
    border-left: 3px solid #4a90e2;
  }
`;

const uppercaseCss = css`
  text-transform: uppercase;
`;

export interface IEventInSchedule {
  eventId: number;
  eventName: string;
  eventStartDate: string;
  eventEndDate: string;
  eventStartTime: string;
  eventEndTime: string;

  programId: number;
  programName: string;
  programType: ProgramTypesEnum;
  sessionId: number;
  sessionName: string;
  sports: number;
  spaces: { spaceId: number; spaceName: string }[];
  coachName?: string;
  isPunchCard: boolean;
}
enum ProgramTypesEnum {
  LEAGUE = 0,
  TOURNAMENT = 1,
  CLASS = 2,
  CLINIC = 3,
  CAMP = 4,
  LESSON = 5,
  CLUB = 6,
}

interface IProps {
  state: IEventInSchedule;
  handleJoin: () => void;
}

export const EventSlot = ({ state, handleJoin }: IProps) => {
  const { colors } = useColors();
  const ref = useRef<HTMLDivElement>(null);
  const { generateDelta } = useTimeFormats();
  const { isShowing, toggle } = useModal();
  const { width } = useWindowSize();
  const diff = generateDelta(
    dayjs(state.eventEndTime, 'HH:mm:ss').diff(
      dayjs(state.eventStartTime, 'HH:mm:ss'),
      'minutes'
    )
  );

  const containerWidth = useMemo(() => {
    if (ref?.current) {
      return ref?.current?.offsetWidth;
    }
    return 300;
  }, [ref?.current?.offsetWidth, width]);

  return (
    <Fragment>
      <div ref={ref} css={container} onClick={toggle}>
        <div css={containerCss(colors)}>
          <Typography
            color={ETypographyColor.primary}
            type={ETypography.overline}
          >
            {`${dayjs(state.eventStartTime, 'HH:mm:ss').format('hh:mma')}- 
            ${dayjs(state.eventEndTime, 'HH:mm:ss').format('hh:mma')}`}
          </Typography>
          {containerWidth > 150 && (
            <Typography
              color={ETypographyColor.teriaty}
              type={ETypography.overline}
            >
              ({diff})
            </Typography>
          )}
        </div>
        <div
          style={{ margin: '8px 0', alignItems: 'flex-start' }}
          css={containerCss(colors)}
        >
          <BnIcon
            style={{
              color: '#4a90e2',
              marginRight: '4px',
              height: '20px',
              width: '20px',
            }}
            icon={sportsIcons[state.sports]}
          />
          <Typography
            style={{ maxWidth: '80%', maxHeight: '40px', overflowY: 'hidden' }}
            color={ETypographyColor.primary}
            type={ETypography.body1Accented}
          >
            {state.programName}
          </Typography>
        </div>
        <div style={{ marginBottom: '4px' }}>
          <Typography
            color={ETypographyColor.secondary}
            type={ETypography.caption}
          >
            {state.sessionName}
          </Typography>
        </div>
        <div css={containerCss(colors)}>
          {state.coachName && (
            <Fragment>
              <div css={containerCss(colors)}>
                <BnIcon icon={Icons.profile_filled} />
                <Typography
                  color={ETypographyColor.secondary}
                  type={ETypography.overline}
                >
                  {state.coachName}
                </Typography>
              </div>
              <div className="divider" />
            </Fragment>
          )}
          {state.spaces.length > 0 && (
            <div css={containerCss(colors)}>
              <BnIcon icon={Icons.location_filled} />
              {state.spaces.map((space, index) => {
                return (
                  <Typography
                    key={index}
                    color={ETypographyColor.secondary}
                    type={ETypography.overline}
                  >
                    {space.spaceName}
                  </Typography>
                );
              })}
            </div>
          )}
        </div>
        {state.isPunchCard && containerWidth > 200 && (
          <div style={{ position: 'absolute', right: 4, bottom: 4 }}>
            <Button theme="basic" sizer="S">
              <Typography
                type={ETypography.body1Accented}
                color={ETypographyColor.primary}
              >
                Join {ProgramTypes[state.programType]}
              </Typography>
            </Button>
          </div>
        )}
      </div>
      <ModalWindow isShowing={isShowing} toggle={toggle} padding={0}>
        <ModalBody
          handleJoin={() => {
            handleJoin();
            toggle();
          }}
          state={state}
          diff={diff}
        />
      </ModalWindow>
    </Fragment>
  );
};

const modalCss = (colors: IColors) => css`
  width: 450px;
  @media (max-width: 768px) {
    width: 350px;
  }
  svg {
    color: ${colors.tx_text_teriaty} !important;
  }
  .modal-header {
    padding: 36px 40px 24px 40px;
    border-bottom: ${colors.bg_border_seperator} 1px solid;
    div {
      display: flex;
      align-items: flex-start;
    }
    .caption {
      margin-left: 34px;
    }
    svg {
      width: 20px;
      height: 20px;
      color: #4a90e2 !important;
    }
    .modal-title {
      margin-bottom: 4px;
      .h3 {
        max-width: 80%;
        max-height: 60px;

        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
      svg {
        margin-top: 4px;
        margin-right: 10px;
      }
    }
  }
  .modal-content {
    padding: 24px 30px 36px 30px;
    .children-container {
      margin-left: 12px;
      margin-top: 2px;
    }
    .modal-dateline {
      display: flex;
      align-items: center;
      .body-2 {
        margin-left: 8px;
      }
    }
  }
  .modal-footer {
    padding: 24px 0;
    border-top: 1px solid ${colors.bg_border_seperator};
    display: flex;
    justify-content: center;
  }
`;

interface IModal extends IProps {
  diff: string;
}

const ModalBody = ({ state, diff, handleJoin }: IModal) => {
  const { colors } = useColors();
  return (
    <div css={modalCss(colors)}>
      <div className="modal-header">
        <div className="modal-title">
          <BnIcon icon={sportsIcons[state.sports]} />
          <Typography color={ETypographyColor.primary} type={ETypography.h3}>
            {state.programName}
          </Typography>
        </div>
        <Typography
          color={ETypographyColor.secondary}
          type={ETypography.caption}
        >
          {state.sessionName}
        </Typography>
      </div>
      <div className="modal-content">
        <ContainerWithIcon icon={Icons.clock} maxWidth="unset">
          <div className="modal-dateline">
            <Typography
              color={ETypographyColor.primary}
              type={ETypography.body1}
            >
              {dayjs(state.eventStartDate).format('ddd, MMM DD')}
            </Typography>
            <Typography
              color={ETypographyColor.primary}
              type={ETypography.body2}
            >
              {`${dayjs(state.eventStartTime, 'HH:mm:ss').format('hh:mm a')} - 
            ${dayjs(state.eventEndTime, 'HH:mm:ss').format('hh:mm a')}`}
            </Typography>
            <Typography
              color={ETypographyColor.secondary}
              type={ETypography.body2}
            >
              ({diff})
            </Typography>
          </div>
        </ContainerWithIcon>
        {state.spaces.length > 0 && (
          <ContainerWithIcon icon={Icons.location} maxWidth="unset">
            {state.spaces.map((space, index) => {
              return (
                <Typography
                  key={index}
                  css={uppercaseCss}
                  color={ETypographyColor.primary}
                  type={ETypography.body1}
                >
                  {space.spaceName}
                </Typography>
              );
            })}
          </ContainerWithIcon>
        )}
      </div>
      {state.isPunchCard && (
        <div className="modal-footer">
          <Button theme="basic" sizer="S" onClick={handleJoin}>
            <Typography
              type={ETypography.body1Accented}
              color={ETypographyColor.primary}
            >
              Join {ProgramTypes[state.programType]}
            </Typography>
          </Button>
        </div>
      )}
    </div>
  );
};
