export const oldThemeColors = {
  brandPrimary: '#0d4774',
  brandPrimaryLight: '#155d94',
  brandSecondary: '#f7b500',
  brandSecondaryLight: '#f7c500',
  textColor: '#0f0238',
  black: '#000',
  white: '#fff',
  dangerRed: '#e23401',
  successGreenText: '#00a35a',
  successGreenBg: '#01e27d',
  btnGreenBg: '#52c483',
  searchTabText: '#535353',
  grayText: '#949494',
  formControlBg: '#f3f7fa',
  formInputBg: '#7c9cb4',
  grayBorder: '#e8e8e8',
  lightGreenBorder: '#869097',
  redBorder: '#e02020',
  darkGray: '#535353',
  whiteLine: '#dae6f0',
  opacityBackground: '#0d4774',
  titleText: '#7c9cb4',
  borderPrimary: '#DAE6F0',
  borderSeperator: '#E2E7EB',
  ligntText: '#A6BCCC',
  statusGreen: '#24C875',
  disabled: '#DBE4EB',
  controllerBackground: '#F6F8FA',
  lightGray: '#FAFCFD',
};

export const ThemeColors = {
  bg_system: '#F7B500',
  bg_text_primary: '#0D4774',
  bg_text_secondary: '#7C9CB4',
  bg_text_teriaty: '#A6BCCC',
  bg_white: '#FFFFFF',
  bg_text_disabled: '#DBE4EB',
  bg_border_primary: '#DAE6F0',
  bg_border_seperator: '#E2E7EB',
  bg_background_primary: '#F3F7FA',
  bg_background_secondary: '#FAFCFD',
  bg_background_overlay: '#7F7F7F',
  bg_background_fields: 'rgba(13, 71, 116, 0.04)',
  bg_error: '#e02020',
  //-----------------------------------------------------------------
  tx_system: '#F7B500',
  tx_text_primary: '#0D4774',
  tx_text_secondary: '#7C9CB4',
  tx_text_teriaty: '#A6BCCC',
  tx_white: '#FFFFFF',
  tx_text_disabled: '#DBE4EB',
  tx_border_primary: '#DAE6F0',
  tx_border_seperator: '#E2E7EB',
  tx_background_primary: '#F3F7FA',
  tx_background_secondary: '#FAFCFD',
  tx_background_overlay: '#7F7F7F',
  tx_background_fields: 'rgba(13, 71, 116, 0.04)',
  tx_error: '#e02020',

  bg_system_gradient: '#F7C500',

  t_green: '#24C875',
  b_green: '#E5F8EE',
};

export const typography = {
  browserBaseFontSize: 16,
  // `px` value, all CSS `rem` values will be relative to this value
  rootFontSize: 10,
  // `body` font-size multiplier of `rootFontSize`
  globalBodyFontSizeBase: 1.8,
  fontPrimary: 'Montserrat',
};
