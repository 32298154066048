import { Icons } from './theme';

export interface InputProps {
  placeholder?: string;
  label?: string;
  info?: string;
  helper?: string;
  helperContent?: string;
  sizer: 'M' | 'S';
  leftIcon?: Icons;
  rightIcon?: Icons;
  error?: string | undefined;
  rightButton?: string | number;
  onRightButtonClick?: () => void;
  setChange?: (val: string | number) => void;
  disableTrailing?: boolean;
  noLabel?: boolean;
  hideError?: boolean;
  isVisited?: boolean;
  clear?: () => void;
  withArrows?: boolean;
  format?: 'default' | 'time';
  clickOutside?: (e: string | number) => void;

  // colors?: IColors;
}

export interface DateInputProps {
  placeholder?: string;
  label?: string;
  info?: string;
  helper?: string;
  helperContent?: string;
  sizer: 'M' | 'S';
  leftIcon?: Icons;
  rightIcon?: Icons;
  error?: string | undefined;
  setChange?: (val: string | number) => void;
  disableTrailing?: boolean;
  clear?: () => void;
  enablePast?: boolean;
  noLabel?: boolean;
  hideError?: boolean;
  notBefore?: string;
  isBirthday?: boolean;

  // colors?: IColors;
}

export interface IOption {
  label: string;
  value: string | number;
}

export interface IPickerProps {
  label: string;
  info?: string;
  sizer: 'M' | 'S';
  options: IOption[];
  selected: (string | number)[];
  setSelected: (v: any) => void;
}


export const sportsIcons = {
    1:Icons.s_softball,
    2:Icons.s_basketball,
    3:Icons.s_football,
    4:Icons.s_soccer,
    5:Icons.s_bowling,
    6:Icons.s_bocceball,
    7:Icons.s_cornhole,
    8:Icons.s_dodgeball,
    9:Icons.s_frisbee,
    10:Icons.s_hockey,
    11:Icons.s_kickball,
    13:Icons.s_pingpong,
    14:Icons.s_rugby,
    15:Icons.s_skeeball,
    16:Icons.s_tennis,
    17:Icons.s_vollyball,
    18:Icons.s_wiffleball,
    19:Icons.s_badminton,
    20:Icons.s_fitness,
    21:Icons.s_gold,
    22:Icons.s_pilatis,
    23:Icons.s_runnning,
    24:Icons.s_skiing,
    25:Icons.s_snowboarding,
    26:Icons.s_yoga,
    27:Icons.s_broomball,
    28:Icons.s_cricket,
    29:Icons.s_crossfit,
    30:Icons.s_cycling,
    31:Icons.s_fieldhockey,
    32:Icons.s_raquetball,
    33:Icons.s_spinning,
    34:Icons.s_squash,
    35:Icons.s_surfing,
    36:Icons.s_swiming,
    37:Icons.s_windsurfing,
    38:Icons.s_adventure,
    39:Icons.s_boxing,
    40:Icons.s_baseball,
    41:Icons.s_dance,
    42:Icons.s_kickboxing,
    43:Icons.s_martialArts,
    44:Icons.s_outdoor,
    45:Icons.s_rowing,
    46:Icons.s_sailing,
    47:Icons.s_sup,
    48:Icons.s_triathlon,
    49:Icons.s_handball,
    50:Icons.s_catchball,
    51:Icons.s_blitzball,
    52:Icons.s_rollerderby,
    53:Icons.s_skating,
    999:Icons.s_other,
}