/** @jsxRuntime classic*/
/** @jsx jsx */
import React from 'react';
import { BnIcon } from '../../components';
import { css, jsx } from '@emotion/react';
import { Icons } from '../../types/theme';
import { TagColorsMapper } from '../../components/Tag/style';

const container = (size: ENotificationSize) => css`
  display: flex;
  align-items: center;
  padding: 8px 12px 8px 8px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  ${size === ENotificationSize.XS &&
  `
    font-size: 12px;
    line-height:15px;
    svg{
      height: 16px;
      width: 16px;
    }
    `}
  ${size === ENotificationSize.S &&
  `
    font-size: 14px;
    line-height:17px;
    svg{
      height: 20px;
      width: 20px;
    }
    `}
  ${size === ENotificationSize.M &&
  `
    font-size: 16px;
    line-height:19px;
    svg{
      height: 24px;
      width: 24px;
    }
    `}
  text-transform: capitalize;
  width: fit-content;
  svg {
    margin-right: 8px;
  }

  &.warning {
    background: ${TagColorsMapper.red.background};
    color: ${TagColorsMapper.red.primary};
  }
  &.action_confirmed {
    background: ${TagColorsMapper.darkGray.background};
    color: ${TagColorsMapper.darkGray.primary};
  }
  &.info {
    background: ${TagColorsMapper.blue.background};
    color: ${TagColorsMapper.blue.primary};
  }
  &.success {
    background: ${TagColorsMapper.green.background};
    color: ${TagColorsMapper.green.primary};
  }
`;

export enum ENotificationType {
  warning = 'warning',
  action_confirmed = 'action_confirmed',
  info = 'info',
  success = 'success',
}

export enum ENotificationSize {
  XS = 'XS',
  S = 'S',
  M = 'M',
}

export interface INotificationProps {
  type: ENotificationType;
  text: string;
  size?: ENotificationSize;
}

export const NotificationMessage = ({
  type,
  text,
  size = ENotificationSize.XS,
}: INotificationProps) => {
  const iconMapper = {
    warning: Icons.conflict,
    action_confirmed: Icons.check,
    info: Icons.info,
    success: Icons.check,
  };

  return (
    <div css={container(size)} className={type}>
      <BnIcon icon={iconMapper[type]} />
      {text}
    </div>
  );
};
