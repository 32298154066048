import styled from '@emotion/styled';
import { IColors } from '../../types/theme';

export const StyledSelectOption = styled.button(
  ({ theme: colors }: { theme: IColors }) => `
    border:none ;
    background:none;

    div{
      display:flex;
      justify-content:space-between;
      cursor:pointer;
      margin:4px;
      padding:12px;
      &:hover{
        background:${colors.bg_background_primary};
      }
      svg{
        width:16px;
        height:16px;
      }

      span{
        display:flex;
        align-items:center;
        text-align:left;
        svg{
          margin-right:8px;
        }
      }
    }

  `
);
