/** @jsxRuntime classic */
/** @jsx jsx */

import React, {
  useState,
  useEffect,
  Fragment,
  useMemo,
  ReactNode,
} from 'react';
import { css, jsx, useTheme } from '@emotion/react';
import {
  Container,
  DotCss,
  maintenanceIconCss,
  downIconCss,
  maintenanceTimeCss,
  maintenanceNameCCss,
} from './style';
import {
  Avatar,
  BnIcon,
  Button,
  Popup,
  Tag,
  Typography,
  useModal,
} from '../../components';
import {
  ETypography,
  ETypographyColor,
  IColors,
  Icons,
} from '../../types/theme';
import { useColors } from '../../hooks/useColors';
import dayjs from 'dayjs';
import { useDelayHover } from '../../hooks/useDelayHover';
import { SlotBody } from './popupBody';
import utc from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);
dayjs.extend(utc);

enum SlotTypeEnum {
  EXTERNAL = 'external',
  INTERNAL = 'internal',
  MAINTENANCE = 'maintenance',
  CUSTOM = 'custom',
}

export const Slot = ({
  event: state,
  triggerEvent,
}: {
  event: any;
  triggerEvent: (v: any) => void;
}) => {
  const [isShowing, setShowing] = useState<boolean>(false);
  const { colors } = useColors();
  const {
    isDiagonal,
    startTime,
    endTime,
    isMaintenance,
    isShort,
    isEditable,
  } = useMemo(() => {
    const isDiagonal = state?.isInternal ? true : false;
    const startTime = state?.startTime ? state?.startTime : state.startDate;
    const endTime = state?.endTime ? state?.endTime : state.endDate;
    const isMaintenance = state.slotType === SlotTypeEnum.MAINTENANCE;

    const diff = dayjs(endTime).diff(dayjs(startTime), 'minutes');
    const isShort = diff <= 30;
    const isEditable = state?.reservation?.creatorType !== 'program_season';
    return {
      isDiagonal,
      startTime,
      endTime,
      isMaintenance,
      isShort,
      isEditable,
    };
  }, [state]);

  const toggle = () => setShowing(!isShowing);

  const { handleMouseEnter, handleMouseLeave } = useDelayHover(500, toggle);

  const Time = `${dayjs.utc(startTime).format('hh:mm')}-${dayjs
    .utc(endTime)
    .format('hh:mma')}`;

  return (
    <Popup
      disableMaxHeight
      disableAutoTrigger
      trigger={isShowing}
      onOpenChange={setShowing}
      padding={0}
      body={
        <SlotBody
          toggle={toggle}
          triggerEvent={triggerEvent}
          state={{ ...state, startTime, endTime }}
          isEditable={isEditable}
        />
      }
    >
      <div
        onClick={toggle}
        css={Container(colors, isDiagonal, isMaintenance, isShort)}
        // onMouseEnter={handleMouseEnter}
        // onMouseLeave={handleMouseLeave}
      >
        {isMaintenance ? (
          <div className="header">
            <div>
              <BnIcon icon={Icons.maintenance} css={maintenanceIconCss} />
            </div>
            <div css={maintenanceTimeCss(isShort)}>
              <Typography
                color={ETypographyColor.primary}
                type={ETypography.captionAccented}
              >
                {state?.reservation?.name}
              </Typography>
              <Typography
                color={ETypographyColor.primary}
                type={ETypography.overline}
              >
                {Time}
              </Typography>
              <Dot />

              {/*<BnIcon icon={Icons.corner_right_down} css={downIconCss} />*/}
            </div>
          </div>
        ) : (
          <Fragment>
            <div className="header">
              <Typography
                color={ETypographyColor.primary}
                type={ETypography.overline}
              >
                {Time}
              </Typography>
              <Dot />
              <Typography
                color={ETypographyColor.primary}
                type={ETypography.overline}
              >
                {state?.reservation?.customer?.name}
              </Typography>
            </div>
            <div className="activity">
              <Typography
                color={ETypographyColor.primary}
                type={ETypography.captionAccented}
              >
                {state?.reservation?.name}
              </Typography>
            </div>
            {/* <div className="occurance">
        <Typography color={ETypographyColor.primary} type={ETypography.caption}>
          Weekly 7/7
        </Typography>
      </div> */}
          </Fragment>
        )}
      </div>
    </Popup>
  );
};

const Dot = () => <span css={DotCss} />;
