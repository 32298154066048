/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react';
import { css, jsx, useTheme } from '@emotion/react';
import { IColors, Icons } from '../../types/theme';
import { Activity } from './all/Activity';
import { Activity_w } from './all/Activity_w';
import { Addons } from './all/Addons';
import { ArrowLeft } from './all/ArrowLeft';
import { ArrowRight } from './all/ArrowRight';
import { ArrowBottom } from './all/Arrow_bottom';
import { ArrowTop } from './all/Arrow_top';
import { Attach } from './all/Attach';
import { Back } from './all/Back';
import { Bank } from './all/Bank';
import { Bell } from './all/Bell';
import { BillAccount } from './all/Bill_account';
import { Browse } from './all/Browse';
import { Calendar } from './all/Calendar';
import { Cart } from './all/Cart';
import { Cash } from './all/Cash';
import { Check } from './all/Check';
import { CheckP } from './all/Check_p';
import { Clipboard } from './all/Clipboard';
import { Clock } from './all/Clock';
import { Close } from './all/Close';
import { Collapse } from './all/Collapse';
import { CollapseAll } from './all/Collapse_all';
import { Conflict } from './all/Conflict';
import { Copy } from './all/Copy';
import { Court } from './all/Court';
import { CreditCard } from './all/Credit_card';
import { Dashboard } from './all/Dashboard';
import { DeleteFilled } from './all/Delete_filled';
import { DeleteOutline } from './all/Delete_outline';
import { Discount } from './all/Discount';
import { Dollar } from './all/Dollar';
import { Download } from './all/Download';
import { Drag } from './all/Drag';
import { Dropdown } from './all/Dropdown';
import { Dropdown_active } from './all/Dropdown_active';
import { Edit_filled } from './all/Edit_filled';
import { EditOutline } from './all/Edit_outline';
import { Email } from './all/Email';
import { Excel } from './all/Excel';
import { ExclamationMark } from './all/Exclamation-mark';
import { Expand } from './all/Expand';
import { ExpandAll } from './all/Expand_all';
import { ExternalLink } from './all/External_link';
import { Filters } from './all/Filters';
import { Flag } from './all/Flag';
import { Forward } from './all/Forward';
import { GlobeFilled } from './all/Globe_filled';
import { Hide } from './all/Hide';
import { Home } from './all/Home';
import { Horizontal } from './all/Horizontal';
import { Image } from './all/Image';
import { Info } from './all/Info';
import { List } from './all/List';
import { Location } from './all/Location';
import { LocationFilled } from './all/Location_filled';
import { LockFilled } from './all/Lock_filled';
import { Logout } from './all/Logout';
import { MaintenanceFilled } from './all/Maintaince_filled';
import { Minus } from './all/Minus';
import { More } from './all/More';
import { Note } from './all/Note';
import { NoteFilled } from './all/Note_filled';
import { Plus } from './all/Plus';
import { ProfileFilled } from './all/Profile_filled';
import { Profiles } from './all/Profiles';
import { QuestionMark } from './all/Question_mark';
import { Remove } from './all/Remove';
import { Replace } from './all/Replace';
import { Reports } from './all/Reports';
import { Search } from './all/Search';
import { Send } from './all/Send';
import { Settings } from './all/Settings';
import { SettingsOutline } from './all/Settings_outline';
import { SettingsOutline2 } from './all/Settings_outline2';
import { Sort } from './all/Sort';
import { Support } from './all/Support';
import { Tag } from './all/Tag';
import { Ticket } from './all/Ticket';
import { Tools } from './all/Tools';
import { Upload } from './all/Upload';
import { Vertical } from './all/Vertical';
import { View } from './all/View';
import { Wallet } from './all/Wallet';
import { Percent } from './all/Percent';
import { Whistle } from './all/Whistle';

import { Adventure } from './sports/Adventure';
import { Badminton } from './sports/Badminton';
import { Baseball } from './sports/Baseball';
import { Basketball } from './sports/Basketball';
import { Blitzball } from './sports/Blitzball';
import { Bocceball } from './sports/Bocceball';
import { Bowling } from './sports/Bowling';
import { Boxing } from './sports/Boxing';
import { Broomball } from './sports/Broomball';
import { Catchball } from './sports/Catchball';
import { Cornhole } from './sports/Cornhole';
import { Cricket } from './sports/Cricket';
import { Crossfit } from './sports/Crossfit';
import { Cycling } from './sports/Cycling';
import { Dance } from './sports/Dance';
import { Dodgeball } from './sports/Dodgeball';
import { FieldHockey } from './sports/FieldHockey';
import { Fitness } from './sports/Fitness';
import { Football } from './sports/Football';
import { Frisbee } from './sports/Frisbee';
import { Golf } from './sports/Golf';
import { Handball } from './sports/Handball';
import { Hockey } from './sports/Hockey';
import { Kickball } from './sports/Kickball';
import { Kickboxing } from './sports/Kickboxing';
import { MartialArts } from './sports/MartialArts';
import { Other } from './sports/Other';
import { Outdoor } from './sports/Outdoor';
import { Pilatis } from './sports/Pilatis';
import { Pingpong } from './sports/Pingpong';
import { Raquetball } from './sports/Raquetball';
import { Rollerderby } from './sports/Rollerderby';
import { Rowing } from './sports/Rowing';
import { Rugby } from './sports/Rugby';
import { Running } from './sports/Running';
import { Sailing } from './sports/Sailing';
import { Skating } from './sports/Skating';
import { Skeeball } from './sports/Skeeball';
import { Skiing } from './sports/Skiing';
import { Snowboarding } from './sports/Snowboarding';
import { Soccer } from './sports/Soccer';
import { Softball } from './sports/Softball';
import { Spinning } from './sports/Spinning';
import { Squash } from './sports/Squash';
import { Sup } from './sports/Sup';
import { Surfing } from './sports/Surfing';
import { Swiming } from './sports/Swiming';
import { Tennis } from './sports/Tennis';
import { Triathlon } from './sports/Triathlon';
import { Vollyball } from './sports/Vollyball';
import { Wiffleball } from './sports/Wiffleball';
import { WindSurfing } from './sports/WindSurfing';
import { Yoga } from './sports/Yoga';

import { Boy } from './gender/boy';
import { BoyAndGirl } from './gender/boyAndGirl';
import { Girl } from './gender/girl';

import { Activity as Activity_pos } from './pos/activity';
import { Apperal } from './pos/apparel';
import { Camp } from './pos/camp';
import { Class } from './pos/class';
import { Drink } from './pos/drink';
import { Equipment } from './pos/equipment';
import { Food } from './pos/food';

import { Beginner } from './levelOfPlay/beginner';
import { Advanced } from './levelOfPlay/advanced';
import { Intermediate } from './levelOfPlay/intermediate';
import { SemiPro } from './levelOfPlay/semiPro';

import { Activity as Activity_A } from './activities/activity';
import { Camps } from './activities/camps';
import { Classes } from './activities/classes';
import { Clinics } from './activities/clinics';
import { ClubTeams } from './activities/clubTeams';
import { Leagues } from './activities/leauges';
import { PrivateLessons } from './activities/privateLessons';
import { Tournaments } from './activities/tournaments';

import { Program_Leagues } from './programs/league';
import { Program_Tournaments } from './programs/tournaments';
import { Program_Camps } from './programs/camps';
import { Program_Clinics } from './programs/clinics';
import { Program_Classes } from './programs/class';
import { Program_Club_Teams } from './programs/club_teams';
import { Program_PrivateLessons } from './programs/private_lessons';
import { CornerRightDown } from './all/CornerRightDown';
import { CorenerRightUp } from './all/CornerRightUp';
import { CornerDownRight } from './all/CornerDownRight';

export interface IconsProps {}

const container = (colors: IColors) => css`
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: ${colors.bg_background_primary};
  color: ${colors.tx_text_primary};
  height: 80px;
  width: 140px;
  border-radius: 6px;

  label {
    margin-top: 8px;
  }
`;

const icon = css``;

const IconsContainerCss = css`
  display: flex;
  flex-wrap: wrap;
`;

const IconBox = ({ i }: { i: Icons }) => {
  const colors = useTheme();
  return (
    <div css={container(colors)}>
      <BnIcon icon={i as Icons} css={icon} />
      <label>{i}</label>
    </div>
  );
};

export const IconsContainer = () => {
  return (
    <div css={IconsContainerCss}>
      {Object.keys(Icons)
        .sort()
        .map((i) => {
          return <IconBox i={i as Icons} />;
        })}
    </div>
  );
};

export const BnIcon = ({ icon, ...props }: any) => {
  return IconnifyNew(icon, props);
};

function IconnifyNew(icon: string, props?: any) {
  switch (icon) {
    case 'activity':
      return <Activity {...props} />;
    case 'activity_w':
      return <Activity_w {...props} />;
    case 'add_ons':
      return <Addons {...props} />;
    case 'arrow_left':
      return <ArrowLeft {...props} />;
    case 'arrow_right':
      return <ArrowRight {...props} />;
    case 'arrow_bottom':
      return <ArrowBottom {...props} />;
    case 'arrow_top':
      return <ArrowTop {...props} />;
    case 'attach':
      return <Attach {...props} />;
    case 'back':
      return <Back {...props} />;
    case 'bank':
      return <Bank {...props} />;
    case 'bell':
      return <Bell {...props} />;
    case 'bill_account':
      return <BillAccount {...props} />;
    case 'browse':
      return <Browse {...props} />;
    case 'calendar':
      return <Calendar {...props} />;
    case 'cart':
      return <Cart {...props} />;
    case 'cash':
      return <Cash {...props} />;
    case 'check':
      return <Check {...props} />;
    case 'check_p':
      return <CheckP {...props} />;
    case 'clipboard':
      return <Clipboard {...props} />;
    case 'clock':
      return <Clock {...props} />;
    case 'close':
      return <Close {...props} />;
    case 'collaps':
      return <Collapse {...props} />;
    case 'collaps_all':
      return <CollapseAll {...props} />;
    case 'conflict':
      return <Conflict {...props} />;
    case 'copy':
      return <Copy {...props} />;
    case 'court':
      return <Court {...props} />;
    case 'credit_card':
      return <CreditCard {...props} />;
    case 'dashboard':
      return <Dashboard {...props} />;
    case 'delete_filled':
      return <DeleteFilled {...props} />;
    case 'delete_outline':
      return <DeleteOutline {...props} />;
    case 'discount':
      return <Discount {...props} />;
    case 'dollar':
      return <Dollar {...props} />;
    case 'download':
      return <Download {...props} />;
    case 'drag':
      return <Drag {...props} />;
    case 'dropdown':
      return <Dropdown {...props} />;
    case 'dropdown_active':
      return <Dropdown_active {...props} />;
    case 'edit_filled':
      return <Edit_filled {...props} />;
    case 'edit_outline':
      return <EditOutline {...props} />;
    case 'email':
      return <Email {...props} />;
    case 'excel':
      return <Excel {...props} />;
    case 'exclamation_mark':
      return <ExclamationMark {...props} />;
    case 'expand':
      return <Expand {...props} />;
    case 'expand_all':
      return <ExpandAll {...props} />;
    case 'external_link':
      return <ExternalLink {...props} />;
    case 'filters':
      return <Filters {...props} />;
    case 'flag':
      return <Flag {...props} />;
    case 'forward':
      return <Forward {...props} />;
    case 'globe_filled':
      return <GlobeFilled {...props} />;
    case 'home':
      return <Home {...props} />;
    case 'hide':
      return <Hide {...props} />;
    case 'horizontal':
      return <Horizontal {...props} />;
    case 'image':
      return <Image {...props} />;
    case 'info':
      return <Info {...props} />;
    case 'list':
      return <List {...props} />;
    case 'location':
      return <Location {...props} />;
    case 'location_filled':
      return <LocationFilled {...props} />;
    case 'lock_filled':
      return <LockFilled {...props} />;
    case 'logout':
      return <Logout {...props} />;
    case 'maintenance':
      return <MaintenanceFilled {...props} />;
    case 'minus':
      return <Minus {...props} />;
    case 'more':
      return <More {...props} />;
    case 'note':
      return <Note {...props} />;
    case 'note_filled':
      return <NoteFilled {...props} />;
    case 'plus':
      return <Plus {...props} />;
    case 'percent':
      return <Percent {...props} />;
    case 'profile_filled':
      return <ProfileFilled {...props} />;
    case 'profiles':
      return <Profiles {...props} />;
    case 'question_mark':
      return <QuestionMark {...props} />;
    case 'remove':
      return <Remove {...props} />;
    case 'replace':
      return <Replace {...props} />;
    case 'reports':
      return <Reports {...props} />;
    case 'whistle':
      return <Whistle {...props} />;

    case 'corner_down_right':
      return <CornerDownRight {...props} />;
    case 'corner_right_down':
      return <CornerRightDown {...props} />;
    case 'corner_right_up':
      return <CorenerRightUp {...props} />;

    case 'g_boy':
      return <Boy {...props} />;
    case 'g_girl':
      return <Girl {...props} />;
    case 'g_boyAndGirl':
      return <BoyAndGirl {...props} />;
    //       case 'g_coord':
    //   return < {...props}/>
    // case 'g_female':
    //   return < {...props}/>
    // case 'g_male':
    //   return < {...props}/>
    // case 'pm_amex':
    //   return < {...props}/>
    // case 'pm_diners':
    //   return < {...props}/>
    // case 'pm_mastercard':
    //   return < {...props}/>
    // case 'pm_visa':
    //   return < {...props}/>

    case 's_adventure':
      return <Adventure {...props} />;
    case 's_badminton':
      return <Badminton {...props} />;
    case 's_baseball':
      return <Baseball {...props} />;
    case 's_basketball':
      return <Basketball {...props} />;
    case 's_blitzball':
      return <Blitzball {...props} />;
    case 's_bocceball':
      return <Bocceball {...props} />;
    case 's_bowling':
      return <Bowling {...props} />;
    case 's_boxing':
      return <Boxing {...props} />;
    case 's_broomball':
      return <Broomball {...props} />;
    case 's_catchball':
      return <Catchball {...props} />;
    case 's_cornhole':
      return <Cornhole {...props} />;
    case 's_cricket':
      return <Cricket {...props} />;
    case 's_crossfit':
      return <Crossfit {...props} />;
    case 's_cycling':
      return <Cycling {...props} />;
    case 's_dance':
      return <Dance {...props} />;
    case 's_dodgeball':
      return <Dodgeball {...props} />;
    case 's_fieldhockey':
      return <FieldHockey {...props} />;
    case 's_fitness':
      return <Fitness {...props} />;
    case 's_football':
      return <Football {...props} />;
    case 's_frisbee':
      return <Frisbee {...props} />;
    case 's_gold':
      return <Golf {...props} />;
    case 's_handball':
      return <Handball {...props} />;
    case 's_hockey':
      return <Hockey {...props} />;
    case 's_kickball':
      return <Kickball {...props} />;
    case 's_kickboxing':
      return <Kickboxing {...props} />;
    case 's_martialArts':
      return <MartialArts {...props} />;
    case 's_other':
      return <Other {...props} />;
    case 's_outdoor':
      return <Outdoor {...props} />;
    case 's_pilatis':
      return <Pilatis {...props} />;
    case 's_pingpong':
      return <Pingpong {...props} />;
    case 's_raquetball':
      return <Raquetball {...props} />;
    case 's_rollerderby':
      return <Rollerderby {...props} />;
    case 's_rowing':
      return <Rowing {...props} />;
    case 's_rugby':
      return <Rugby {...props} />;
    case 's_runnning':
      return <Running {...props} />;
    case 's_sailing':
      return <Sailing {...props} />;
    case 's_skating':
      return <Skating {...props} />;
    case 's_skeeball':
      return <Skeeball {...props} />;
    case 's_skiing':
      return <Skiing {...props} />;
    case 's_snowboarding':
      return <Snowboarding {...props} />;
    case 's_soccer':
      return <Soccer {...props} />;
    case 's_softball':
      return <Softball {...props} />;
    case 's_spinning':
      return <Spinning {...props} />;
    case 's_squash':
      return <Squash {...props} />;
    case 's_sup':
      return <Sup {...props} />;
    case 's_surfing':
      return <Surfing {...props} />;
    case 's_swiming':
      return <Swiming {...props} />;
    case 's_tennis':
      return <Tennis {...props} />;
    case 's_triathlon':
      return <Triathlon {...props} />;
    case 's_vollyball':
      return <Vollyball {...props} />;
    case 's_wiffleball':
      return <Wiffleball {...props} />;
    case 's_windsurfing':
      return <WindSurfing {...props} />;
    case 's_yoga':
      return <Yoga {...props} />;

    case 'search':
      return <Search {...props} />;
    case 'send':
      return <Send {...props} />;
    case 'settings':
      return <Settings {...props} />;
    case 'settings_outline':
      return <SettingsOutline {...props} />;
    case 'settings_outline2':
      return <SettingsOutline2 {...props} />;
    case 'sort':
      return <Sort {...props} />;
    case 'support':
      return <Support {...props} />;
    case 'tag':
      return <Tag {...props} />;
    case 'ticket':
      return <Ticket {...props} />;
    case 'tools':
      return <Tools {...props} />;
    case 'upload':
      return <Upload {...props} />;
    case 'vartical':
      return <Vertical {...props} />;
    case 'view':
      return <View {...props} />;
    case 'wallet':
      return <Wallet {...props} />;
    case 'pos_food':
      return <Food {...props} />;
    case 'pos_activity':
      return <Activity_pos {...props} />;
    case 'pos_apparel':
      return <Apperal {...props} />;
    case 'pos_camp':
      return <Camp {...props} />;
    case 'pos_class':
      return <Class {...props} />;
    case 'pos_drink':
      return <Drink {...props} />;
    case 'pos_equipment':
      return <Equipment {...props} />;
    case 'beginner':
      return <Beginner {...props} />;
    case 'advanced':
      return <Advanced {...props} />;
    case 'intermediate':
      return <Intermediate {...props} />;
    case 'semipro':
      return <SemiPro {...props} />;

    case 'act_activity':
      return <Activity_A {...props} />;
    case 'act_camps':
      return <Camps {...props} />;
    case 'act_classes':
      return <Classes {...props} />;
    case 'act_clinics':
      return <Clinics {...props} />;
    case 'act_clubTeams':
      return <ClubTeams {...props} />;
    case 'act_leagues':
      return <Leagues {...props} />;
    case 'act_privateLessons':
      return <PrivateLessons {...props} />;
    case 'act_tournaments':
      return <Tournaments {...props} />;

    case 'pro_camp':
      return <Program_Camps {...props} />;
    case 'pro_class':
      return <Program_Classes {...props} />;
    case 'pro_clinic':
      return <Program_Clinics {...props} />;
    case 'pro_club_team':
      return <Program_Club_Teams {...props} />;
    case 'pro_league':
      return <Program_Leagues {...props} />;
    case 'pro_private_lesson':
      return <Program_PrivateLessons {...props} />;
    case 'pro_tournament':
      return <Program_Tournaments {...props} />;
    default:
      return <div></div>;
  }
}
