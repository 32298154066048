import React from 'react';
import { useTheme } from '@emotion/react';
import { StyledTag } from './style';

export type TAllowedColors =
  | 'blue'
  | 'yellow'
  | 'red'
  | 'green'
  | 'purple'
  | 'gray';

export interface Props {
  title: string;
  color: TAllowedColors;
}

export const Tag = ({ title, color }: Props) => {
  const colors = useTheme();
  return (
    <>
      {title && (
        <StyledTag theme={colors} color={color}>
          {title}
        </StyledTag>
      )}
    </>
  );
};
