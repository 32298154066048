/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { IColors } from '../../types/theme';

export const stepContainerCss = css`
  display: flex;
  margin-top: 16px;
  margin-bottom: 8px;
`;

export const stepCss = (
  colors: IColors,
  isActive: boolean,
  isPassed: boolean
) => css`
  display: flex;
  font-family: Montserrat;
  position: relative;
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 1.7rem;
  color: ${isActive || isPassed
    ? colors.tx_text_primary
    : colors.tx_text_teriaty};
  align-items: center;
  .step {
    border: 1px solid
      ${isPassed
        ? 'none'
        : isActive
        ? colors.tx_text_primary
        : colors.tx_text_teriaty};
    border-radius: 100px;
    background: ${isPassed ? colors.bg_text_primary : 'none'};
    color: ${isPassed ? colors.tx_white : 'none'};
    box-sizing: border-box;
    padding: ${isPassed ? '0 2px' : '0 5px'};
    width: 1.9rem;
    height: 1.9rem;
    text-align: center;
    margin-right: 5px;
    align-items: center;
    display: flex;
    justify-content: center;
  }
  .step-label {
  }
  .step-border {
    background: ${isActive ? colors.bg_text_primary : 'transparent'};
    position: absolute;
    bottom: -9px;
    height: 3px;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 8px;
  }
`;

export const dividerCss = (colors: IColors) => css`
  width: 20px;
  height: 12px;
  border-bottom: 1px dashed ${colors.tx_text_teriaty};
  margin: 0 10px;
`;
