import { ColorInput, TinyColor } from '@ctrl/tinycolor';

export function setOpacity(
  // Any valid color that TinyColor accepts
  color: ColorInput,
  // Opacity level (from 0 to 1)
  opacity: number
) {
  return new TinyColor(color).setAlpha(opacity).toRgbString();
}
