/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import dayjs from 'dayjs';
import { Avatar, BnIcon, Button, Tag, Typography } from '../../components';
import { ContainerWithIcon } from '../../components/containerWithIcon';
import { useColors } from '../../hooks/useColors';
import {
  ETypography,
  ETypographyColor,
  IColors,
  Icons,
} from '../../types/theme';
import utc from 'dayjs/plugin/utc';
import { useTimeFormats } from '../../hooks/useTimeFormats';
dayjs.extend(utc);

const closeButton = css`
  position: absolute;
  top: 16px;
  right: 16px;
`;

const slotHeader = css`
  display: flex;
  flex-direction: column;
  padding: 36px 40px 24px 40px;
  .line {
    display: flex;
    align-items: center;
    div {
      margin-right: 4px;
    }
  }
`;

const dividerCss = (colors: IColors) => css`
  border-bottom: 1px solid ${colors.bg_border_seperator};
`;

const bodyCss = css`
  padding: 24px 40px;
  display: flex;
  flex-direction: column;
  .line {
    display: flex;
    align-items: center;
    div {
      margin-right: 4px;
    }
  }
`;

const footerCss = css`
  display: flex;
  padding: 16px 40px;
  justify-content: flex-end;
`;

export const SlotBody = ({
  state,
  toggle,
  isEditable,
  triggerEvent,
}: {
  state: any;
  toggle: () => void;
  isEditable: boolean;
  triggerEvent: (v: any) => void;
}) => {
  const { generateDelta } = useTimeFormats();
  const { colors } = useColors();

  const diff = dayjs
    .utc(state.endTime)
    .diff(dayjs.utc(state.startTime), 'minutes');

  const reservationStatusMapper = {
    active: 'green',
  };

  const isProgram = state?.reservation?.creatorType === 'program_season';

  return (
    <div style={{ minWidth: '400px', position: 'relative' }}>
      <div css={closeButton}>
        <Button sizer="S" theme="basic" onClick={toggle}>
          <BnIcon icon={Icons.close} />
        </Button>
      </div>
      <div css={slotHeader}>
        <Typography color={ETypographyColor.primary} type={ETypography.h4}>
          {state?.reservation?.name}
        </Typography>
        <div className="line">
          <Typography
            color={ETypographyColor.secondary}
            type={ETypography.caption}
          >
            Reservation
          </Typography>

          <Typography
            color={ETypographyColor.primary}
            type={ETypography.captionLink}
          >
            #{state.reservationId}
          </Typography>
          {/* <Tag color="yellow" title="Partially Paid" /> */}
          <Tag
            color={
              reservationStatusMapper[state?.reservation?.status] || 'yellow'
            }
            title={state?.reservation?.status}
          />
        </div>
        {/* <div className="line">
          <Typography
            color={ETypographyColor.secondary}
            type={ETypography.caption}
          >
            Created by
          </Typography>
          <Typography
            color={ETypographyColor.primary}
            type={ETypography.caption}
          >
            {state?.reservation?.userCreatorId}
          </Typography>
        </div> */}
      </div>
      <div css={dividerCss(colors)} />
      <div css={bodyCss}>
        <div className="line">
          <Avatar />
          <div style={{ marginLeft: '10px' }}>
            <Typography
              color={ETypographyColor.primary}
              type={ETypography.body2Accented}
            >
              {state?.reservation?.customer?.name}
            </Typography>
          </div>
          {/* <Tag color="blue" title="New" /> */}
        </div>
        <ContainerWithIcon icon={Icons.clock}>
          <div className="line">
            <Typography
              color={ETypographyColor.primary}
              type={ETypography.body2}
            >
              {dayjs.utc(state?.startDate).format('ddd, MMM DD')}
            </Typography>
            <Typography
              color={ETypographyColor.primary}
              type={ETypography.body2}
            >
              {dayjs.utc(state?.startTime).format('hh:mma')}
            </Typography>
            <Typography
              color={ETypographyColor.primary}
              type={ETypography.body2}
            >
              -
            </Typography>
            <Typography
              color={ETypographyColor.primary}
              type={ETypography.body2}
            >
              {dayjs.utc(state?.endTime).format('hh:mma')}
            </Typography>
            <Typography
              color={ETypographyColor.teriaty}
              type={ETypography.overlineAccented}
            >
              {`( ${generateDelta(diff)} )`}
            </Typography>
          </div>
          {/* <div className="line">
            <Typography
              color={ETypographyColor.secondary}
              type={ETypography.caption}
            >
              Weekly on Mon & Wed
            </Typography>
            <Typography
              color={ETypographyColor.secondary}
              type={ETypography.caption}
            >
              1 / 3 Slots
            </Typography>
          </div> */}
        </ContainerWithIcon>
        {state?.space?.name && (
          <ContainerWithIcon icon={Icons.location}>
            <div className="line">
              <Typography
                color={ETypographyColor.primary}
                type={ETypography.body2}
              >
                {state?.space?.name}
              </Typography>
              {/* <Typography
              color={ETypographyColor.teriaty}
              type={ETypography.overlineAccented}
            >
              $120/Hr
            </Typography> */}
            </div>
          </ContainerWithIcon>
        )}
        {isProgram && false && (
          <ContainerWithIcon icon={Icons.profiles}>
            <div className="line">should be participants</div>
          </ContainerWithIcon>
        )}
        {state?.reservation?.description && (
          <ContainerWithIcon icon={Icons.note}>
            <Typography
              color={ETypographyColor.primary}
              type={ETypography.body2}
            >
              {state?.reservation?.description}
            </Typography>
          </ContainerWithIcon>
        )}
        {state?.publicNotes && (
          <ContainerWithIcon icon={Icons.note_filled}>
            <Typography
              color={ETypographyColor.primary}
              type={ETypography.body2}
            >
              {state?.publicNotes}
            </Typography>
          </ContainerWithIcon>
        )}
        {/* <ContainerWithIcon icon={Icons.add_ons}>
          <Typography
            color={ETypographyColor.teriaty}
            type={ETypography.overlineAccented}
          >
            Add-Ons
          </Typography>
          <div className="line">
            <Typography
              color={ETypographyColor.primary}
              type={ETypography.body2}
            >
              Filed Sixth 2
            </Typography>
            <Typography
              color={ETypographyColor.teriaty}
              type={ETypography.overlineAccented}
            >
              $120/Hr
            </Typography>
          </div>
        </ContainerWithIcon> */}
      </div>
      <div css={dividerCss(colors)} />
      {isEditable && (
        <div css={footerCss}>
          <Button sizer="S" theme="primary" onClick={() => triggerEvent(state)}>
            edit
          </Button>
        </div>
      )}
    </div>
  );
};
