import styled from '@emotion/styled';
import { IColors } from '../../types/theme';

export const StyledRadio = styled.label(
  ({
    theme: colors,
    width,
    active,
  }: {
    theme: IColors;
    width: number;
    active: boolean;
  }) => `
  display: block;
  position: relative;
  padding-left: ${width + 15}px;
  margin:8px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

    div{ 
        color: ${colors.tx_text_primary};
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
    }
    .M{
      font-size: 16px;
      line-height: ${width + 2}px;
    }
    .S{

      font-size: 14px;
      line-height: ${width + 2}px;
    }

  input{
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: ${width}px;
    width: ${width}px;
    background-color: ${colors.bg_background_fields};
    
    border-radius:10000000px;
    border: 1px solid ${colors.bg_background_fields};
  }
  &:hover input ~ .checkmark {
    border: 1px solid ${colors.bg_border_primary};
  }
  input:checked ~ .checkmark {
    // background-color: #2196F3;
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  .checkmark:after {
    display:${active ? 'block' : 'none'} ;
  }

  .checkmark:after {
    background:${colors.bg_text_primary};
    height: 90%;
    top: 50%;
    left: 50%;
    border-radius: 10000000px;
    width: 90%;
    content: "";
    transform: translate(-50%, -50%);
  }

  .error{
    border: 1px solid ${colors.bg_error};
  }
  .error:hover {
    border: 1px solid ${colors.bg_error} !important;
  }
  `
);
