import React, {
  FC,
  useRef,
  Fragment,
  forwardRef,
  useEffect,
  useState,
} from 'react';
import { useModal } from '../../hooks/useModal';
import { FormSelect } from '../Select/FormSelect';
import { Modal as ModalWindow } from './modal';

export interface IModal {
  body: React.ReactNode;
  children?: React.ReactNode;
  callback?: () => void;
  padding?: number;
}
export const Modal = forwardRef<HTMLDivElement, IModal>(
  ({ children, body, callback = () => {}, padding = 1 }, ref = null) => {
    const { isShowing, toggle } = useModal();

    useEffect(() => {
      callback();
    }, [isShowing]);

    return (
      <Fragment>
        <div onClick={toggle}>{children}</div>
        <ModalWindow
          padding={padding}
          ref={ref}
          isShowing={isShowing}
          toggle={toggle}
        >
          {body}
        </ModalWindow>
      </Fragment>
    );
  }
);
