import { useRecoilState } from 'recoil';
import { Store } from '../store';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);
dayjs.extend(utc);

export const usePermissions = () => {
  const [permissions, setPermissions] = useRecoilState(Store.permissions);
  const [currentTab, setCurrentTab] = useRecoilState(Store.currentTab);

  const getPermissions = async (
    fetchPermissios: () => Promise<{ data: any[] }>
  ) => {
    const lastRefreshedTimestamp =
      localStorage.getItem('lastRefreshedTimestamp') || 0;

    const newTime = lastRefreshedTimestamp ? Number(lastRefreshedTimestamp) : 0;
    if (dayjs().utc().diff(dayjs(newTime).utc(), 'minutes') > 10) {
      localStorage.setItem(
        'lastRefreshedTimestamp',
        String(dayjs().utc().valueOf())
      );
      fetchPermissios().then(({ data }) => {
        let permissions: string[] = [];
        data.forEach((role) => {
          role.permissions.forEach((permission: { name: string }) => {
            if (!permissions.includes(permission.name)) {
              permissions.push(permission.name);
            }
          });
        });
        setPermissions(permissions);
      });
    }
  };

  return {
    permissions,
    setPermissions,
    currentTab,
    getPermissions,
    setCurrentTab,
  };
};
