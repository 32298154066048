import styled from '@emotion/styled';
import { IColors } from '../../types/theme';

export const StyledTimeInput = styled.div(
  ({ theme: colors }: { theme: IColors }) => `
    display:flex;
    width:100%;
      
    `
);
