import React from 'react';
import { IColors, ITypography } from '../../types/theme';
import { css, jsx } from '@emotion/react';

export const ProductCardContainer = (
  colors: IColors,
  typography: ITypography,
  isExpand: boolean,
  maxHeight: null | number,
  withCta: boolean
) => css`
  background: ${colors.bg_background_secondary};
  border: 1px solid ${colors.bg_border_primary};
  box-sizing: border-box;
  border-radius: 5px;
  max-width: 300px;
  position: relative;
  .header {
    padding: 12px 16px;
    display: flex;
    flex-direction: column;
    svg {
      width: 20px;
      height: 20px;
      margin-right: 8px;
      color: ${colors.tx_text_secondary};
    }
    .title {
      margin-bottom: 10px;
    }
    .more-menu {
      position: absolute;
      right: 0;
      top: 2.5px;
    }
    div {
      display: flex;
      align-items: center;
    }
  }

  .body {
    padding: 12px 16px;
    .prices {
      margin-bottom: 16px;
      .price-line {
        display: flex;
        justify-content: space-between;
        margin-top: 13.5px;
        .left-side {
          display: flex;
          flex-direction: column;
          div {
            display: flex;
            align-items: center;
          }
        }
      }
    }

    .prorated {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      svg {
        color: ${colors.tx_text_primary};
        height: 16px;
        width: 16px;
        margin-right: 4px;
      }
    }

    .discounts {
      margin-top: 13.5px;
      .discount-line {
        margin-top: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
    .paymentPlan {
      margin-top: 13.5px;
      margin-bottom: 12px;
      .deposit {
        margin: 8px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }

  .divider {
    width: 100%;
    height: 1px;
    background: ${colors.bg_border_seperator};
  }

  .show-button {
    background: transparent;
    border: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
    margin-top: 12px;
  }

  .collapsable {
    max-height: ${withCta
      ? isExpand
        ? maxHeight
          ? maxHeight + 'px'
          : '1000px'
        : '140px'
      : 'unset'};
    overflow-y: hidden;
    transition: all 750ms;
  }
  .footer {
    display: flex;
    justify-content: flex-end;
    padding: 12px 16px;
    button {
      margin-left: 8px;
    }
    .icon {
      height: 16px;
      width: 16px;
      margin-right: 4px;
    }
  }
`;

export const underline = css`
  text-decoration: underline;
`;
