import * as  React from 'react';

export const BnClose = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.2216 10.0001L16.4135 4.80814C16.7513 4.47042 16.7513 3.92438 16.4135 3.58666C16.0757 3.24894 15.5297 3.24894 15.192 3.58666L9.99998 8.77866L4.80814 3.58666C4.47026 3.24894 3.92438 3.24894 3.58666 3.58666C3.24878 3.92438 3.24878 4.47042 3.58666 4.80814L8.7785 10.0001L3.58666 15.1921C3.24878 15.5299 3.24878 16.0759 3.58666 16.4136C3.75496 16.5821 3.97626 16.6667 4.1974 16.6667C4.41854 16.6667 4.63967 16.5821 4.80814 16.4136L9.99998 11.2216L15.192 16.4136C15.3604 16.5821 15.5816 16.6667 15.8027 16.6667C16.0239 16.6667 16.245 16.5821 16.4135 16.4136C16.7513 16.0759 16.7513 15.5299 16.4135 15.1921L11.2216 10.0001Z"
      fill="currentColor"
    />
  </svg>
);
