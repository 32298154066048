import { rcEnums } from './rc.enums';

export class GroupItemsPricing {
  organizationId: number;
  groupId: number;

  itemId: number;
  itemType: rcEnums.shared.ResourceNameTypeEnum;

  startDate: Date;
  endDate: Date;

  price: number;
  overridesPrice: boolean;

  group?: EntitlementGroup;

  discountValue: number;
  discountMethod: DiscountMethodsEnum;
}
export class EntitlementGroup {
  organizationId: number;
  name: string;
  id: number;
}

export enum DiscountMethodsEnum {
  PERCENT = 'percent',
  AMOUNT = 'amount',
}
