import { useTheme } from '@emotion/react';
import React from 'react';
import { EBrandingColorsOptions, IColors } from '../../types/theme';
// import './index.scss';
import { StyledLoader } from './style';

export const Loader = ({
  color = EBrandingColorsOptions.tx_white,
  width = 20,
}: {
  color?: EBrandingColorsOptions;
  width?: number;
}) => {
  const colors = useTheme();

  return (
    <StyledLoader color={colors[color]} width={width}>
      <div className="sk-fading-circle">
        <div className="sk-circle1 sk-circle"></div>
        <div className="sk-circle2 sk-circle"></div>
        <div className="sk-circle3 sk-circle"></div>
        <div className="sk-circle4 sk-circle"></div>
        <div className="sk-circle5 sk-circle"></div>
        <div className="sk-circle6 sk-circle"></div>
        <div className="sk-circle7 sk-circle"></div>
        <div className="sk-circle8 sk-circle"></div>
        <div className="sk-circle9 sk-circle"></div>
        <div className="sk-circle10 sk-circle"></div>
        <div className="sk-circle11 sk-circle"></div>
        <div className="sk-circle12 sk-circle"></div>
      </div>
    </StyledLoader>
  );
};
