import React from 'react';
import { IColors, ITypography } from '../../types/theme';
import { css, jsx } from '@emotion/react';
import { setOpacity } from '../../styles/utils';

export const Container = (
  colors: IColors,
  isDiagonal: boolean,
  isMaintenance: boolean = false,
  isShort: boolean = false
) => css`
  background: ${isMaintenance ? '#E2E7EB' : '#edf4fc'};
  border-left: 3px solid ${isMaintenance ? '#9B9B9B' : '#4a90e2'};
  height: 100%;
  border-radius: 2px 0 0 2px;
  padding: ${isShort ? '0' : '8px'};
  padding-left: 5px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  .header {
    display: flex;
    align-items: ${isShort ? 'flex-start' : 'center'};
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  &:before {
    content: '';

    display: ${isDiagonal ? 'block' : 'none'};
    height: 100%;
    width: 200%;
    position: absolute;
    background-image: linear-gradient(#4a90e2 3px, transparent 0.1em);
    opacity: 0.2;
    background-size: 25px 25px;
    top: 0;
    left: 0;
    transform: rotate(-65deg) translate(-15%, -25%);
  }
`;

export const DotCss = (colors: IColors) => css`
  background: ${colors.bg_text_primary};
  height: 2px;
  width: 2px;
  min-width: 2px;
  margin: 0 4px;
  display: block;
  border-radius: 10px;
`;

export const gridCss = css`
  height: 100%;
  width: 200%;
  position: absolute;
  background-image: linear-gradient(#1e1e1e 2px, transparent 0.1em);
  background-size: 30px 30px;
  top: 0;
  left: 0;
  transform: rotate(-45deg) translate(-20%, -20%);
`;

export const maintenanceIconCss = css`
  width: 15px;
  height: 15px;
  color: #9b9b9b;
`;

export const downIconCss = css`
  width: 12px;
  height: 12px;
  color: #4e4e4e;
`;

export const maintenanceTimeCss = (isShort: boolean) => css`
  display: flex;
  align-items: ${isShort ? 'flex-start' : 'center'};
  .caption-accented {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    ${isShort && 'font-size:10px;'}
    ${isShort && 'line-height:10px;'}
    width: 50%;
  }
  .caption-accented,
  .overline {
    color: #4e4e4e !important;
    margin-left: 8px;
  }
`;
export const maintenanceNameCCss = css`
  color: #4e4e4e;
  font-size: 10px;
  line-height: 1.2rem;
  font-family: Montserrat;
  line-height: 1.2rem;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 5.3px;
  padding-right: 6.5px;
  font-weight: 600;
`;
