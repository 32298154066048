import React, { FC, useEffect } from 'react';
import { useColors } from '../../hooks/useColors';
import { ThemeProvider } from '@emotion/react';
import { IColors, IBnProviderSettings } from '../../types/theme';
import { RecoilRoot } from 'recoil';
import { usePermissions } from '../../hooks/usePermissions';

export const BnThemeProvider: FC<IBnProviderSettings> = ({
  children,
  colors,
  fetchPermissios,
}) => {
  const { colors: ThemeColors, setColors } = useColors();
  const { getPermissions } = usePermissions();

  useEffect(() => {
    if (fetchPermissios) {
      getPermissions(fetchPermissios);
      window.addEventListener('focus', () => getPermissions(fetchPermissios));

      return () => {
        window.removeEventListener('focus', () =>
          getPermissions(fetchPermissios)
        );
      };
    }
    return;
  }, []);

  useEffect(() => {
    if (colors) {
      if (JSON.stringify(colors) !== JSON.stringify(ThemeColors)) {
        setColors({ ...ThemeColors, ...colors });
      }
    }
  }, [colors]);
  return (
    <ThemeProvider theme={ThemeColors as IColors}>{children}</ThemeProvider>
  );
};

export const BnProvider: FC<IBnProviderSettings> = ({
  children,
  colors,
  fetchPermissios,
}) => {
  return (
    <RecoilRoot>
      <BnThemeProvider fetchPermissios={fetchPermissios} colors={colors}>
        {children}
      </BnThemeProvider>
    </RecoilRoot>
  );
};
