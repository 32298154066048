/** @jsxRuntime classic */
/** @jsx jsx */

import React, { FC, Fragment, useEffect, forwardRef } from 'react';
import { jsx, css, useTheme } from '@emotion/react';
import { BnClose } from '../../icons';
import { IColors } from '../../types/theme';

const modalContainerCss = (padding: string, colors: IColors) => css`
  position: fixed;
  box-shadow: 0px 2px 22px rgba(21, 93, 148, 0.0749563);
  // width: 100%;
  padding: ${padding ? padding + 'rem' : '1rem'};
  overflow-y: auto;
  background: ${colors.bg_white};
  z-index: 2002;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 90%;
  max-width: 60%;
  @media (max-width: 768px) {
    max-width: 90%;
  }
`;

const closeButtonCss = (colors: IColors) => css`
  position: absolute;
  right: 0.75rem;
  top: 0.75rem;
  border-radius: 200px;
  display: flex;
  padding: 0.25rem;
  outline: none;
  border: 0;
  background: transparent;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  &:hover {
    background: ${colors.bg_text_disabled};
  }
`;

const iconCss = css`
  div {
    display: flex;
    align-items: center;
  }
`;

interface Props {
  toggle: () => void;
  isDismissable: boolean;
  background?: string;
  maxWidth?: string;
  padding?: number;
  children?: React.ReactNode;
}

export const Container = forwardRef<HTMLDivElement, Props>(
  (
    { children, toggle, background, padding, maxWidth, isDismissable },
    ref = null
  ) => {
    const colors = useTheme();
    return (
      <div ref={ref} css={modalContainerCss(String(padding), colors)}>
        {isDismissable && (
          <button onClick={toggle} css={closeButtonCss(colors)}>
            <BnClose />
          </button>
        )}
        {children}
      </div>
    );
  }
);
