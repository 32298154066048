import styled from '@emotion/styled';
import { IColors } from '../../types/theme';
import { TAllowedColors } from './index';

export const TagColorsMapper = {
  yellow: {
    primary: '#F7B500',
    background: '#FEF4D9',
  },
  red: {
    primary: '#E02020',
    background: '#FBE4E4',
  },
  green: {
    primary: '#24C875',
    background: '#E5F8EE',
  },
  purple: {
    primary: '#A433C5',
    background: '#F4E7F8',
  },
  blue: {
    primary: '#4A90E2',
    background: '#EDF4FC',
  },
  gray: {
    primary: '#9B9B9B',
    background: '#E2E7EB',
  },
  darkGray: {
    primary: 'white',
    background: 'rgba(0,0,0,0.5)',
  },
};

export const StyledTag = styled.div(
  ({ theme: colors, color }: { theme: IColors; color: TAllowedColors }) => `
  background: ${TagColorsMapper[color].background};
  color: ${TagColorsMapper[color].primary};
  border-radius: 2px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  padding: 3px 4px;
  margin: 4px 0;
  font-size: 1rem;
  line-height: 12px;
  text-align: center;
  text-transform: capitalize;
  margin-right: 5px;
  width: fit-content;
  font-style: normal;
  font-size: 10px;
  height: fit-content;
  `
);
