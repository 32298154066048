import React, { CSSProperties } from 'react';
import styled from '@emotion/styled';
import { IColors, Icons } from '../../types/theme';
import { BnIcon } from '../../components/icons_v2';
import { useTheme } from '@emotion/react';

const Img = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 100px;
`;

const ImgPlaceholder = styled.div(
  ({ theme: colors }: { theme: IColors }) => `
    width: 24px;
    height: 24px;
    border-radius: 100px;
    box-sizing: border-box;
    border:1px solid ${colors.tx_border_primary};
    background:${colors.bg_white};
    display:flex;
    align-items:center;
    justify-content: center;
    color:${colors.tx_border_primary};
    svg{
        height: 14px;
        width: 14px;
    }
    `
);

const Check = styled.div(
  ({ theme: colors }: { theme: IColors }) => `
  height: 14px;
  width: 14px;
  background: ${colors.b_green};
  border: 1px solid ${colors.bg_white};
  box-shadow: 0px 2px 22px 8px rgba(21, 93, 148, 0.0749563);
  border-radius: 100px;
  position:absolute;
  bottom: 0;
    display: flex;
    align-items: center;
    right: 0;
    box-sizing: border-box;
    transform: translate(50%, 15%);
    svg{
        color:${colors.t_green} !important;
        height: 14px;
        width: 14px;
        
    }
  `
);

export const Avatar = ({
  img,
  imgStyle,
  checked,
}: {
  img?: string;
  imgStyle?: CSSProperties;
  checked?: boolean;
}) => {
  const theme = useTheme();
  return (
    <div style={{ width: 'min-content', position: 'relative' }}>
      {img ? (
        <Img src={img} style={imgStyle} onError={(e) => {}} alt="" />
      ) : (
        <ImgPlaceholder style={imgStyle} theme={theme}>
          <BnIcon icon={Icons.profile_filled} />
        </ImgPlaceholder>
      )}
      {checked && (
        <Check theme={theme}>
          <BnIcon theme={theme} icon={Icons.check} />
        </Check>
      )}
    </div>
  );
};
