import styled from '@emotion/styled';

export const PopUpButton = styled.div`
  background: none;
  border: none;
  outline: none;
  padding: 0;
  width: 100%;
  height: 100%;

  &:focus {
    outline: none;
  }
`;

export const PopUpBody = styled.div(
  ({
    padding,
    borderRadius,
    disableMaxHeight,
  }: {
    padding: number | string;
    borderRadius: number | string;
    disableMaxHeight: boolean;
  }) => `
  background: white;
  box-shadow: 0px 2px 22px 8px rgba(21, 93, 148, 0.0749563);
  border: 1px solid #e7e7e7;
  position: relative;
  padding: ${padding}rem;
  border-radius: ${borderRadius}px;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  max-height: ${disableMaxHeight ? 'unset' : '400px'};
`
);
