export interface IColors {
  bg_system?: string;
  bg_text_primary?: string;
  bg_text_secondary?: string;
  bg_text_teriaty?: string;
  bg_white?: string;
  bg_text_disabled?: string;
  bg_border_primary?: string;
  bg_border_seperator?: string;
  bg_background_primary?: string;
  bg_background_secondary?: string;
  bg_background_overlay?: string;
  bg_background_fields?: string;
  bg_error?: string;
  tx_system?: string;
  tx_text_primary?: string;
  tx_text_secondary?: string;
  tx_text_teriaty?: string;
  tx_white?: string;
  black?: string;
  tx_text_disabled?: string;
  tx_border_primary?: string;
  tx_border_seperator?: string;
  tx_background_primary?: string;
  tx_background_secondary?: string;
  tx_background_overlay?: string;
  tx_background_fields?: string;
  tx_error?: string;
  bg_system_gradient?: string;
  t_green?: string;
  b_green?: string;

}

export enum EBrandingColorsOptions {
  bg_system = 'bg_system',
  bg_text_primary = 'bg_text_primary',
  bg_text_secondary = 'bg_text_secondary',
  bg_text_teriaty = 'bg_text_teriaty',
  bg_white = 'bg_white',
  bg_text_disabled = 'bg_text_disabled',
  bg_border_primary = 'bg_border_primary',
  bg_border_seperator = 'bg_border_seperator',
  bg_background_primary = 'bg_background_primary',
  bg_background_secondary = 'bg_background_secondary',
  bg_background_overlay = 'bg_background_overlay',
  bg_background_fields = 'bg_background_fields',
  bg_error = 'bg_error',
  tx_system = 'tx_system',
  tx_text_primary = 'tx_text_primary',
  tx_text_secondary = 'tx_text_secondary',
  tx_text_teriaty = 'tx_text_teriaty',
  tx_white = 'tx_white',
  tx_text_disabled = 'tx_text_disabled',
  tx_border_primary = 'tx_border_primary',
  tx_border_seperator = 'tx_border_seperator',
  tx_background_primary = 'tx_background_primary',
  tx_background_secondary = 'tx_background_secondary',
  tx_background_overlay = 'tx_background_overlay',
  tx_background_fields = 'tx_background_fields',
  tx_error = 'tx_error',
  btnGreenBg = 'btnGreenBg',
  successGreenText = 'successGreenText',
  successGreenBg = 'successGreenBg',
  black = 'black',
  opacityBackground = 'opacityBackground',
  bg_system_gradient = 'bg_system_gradient',
  t_green = 't_green',
  b_green = 'b_green',
}
export interface ITypography {
  browserBaseFontSize?: number;
  rootFontSize?: number;
  globalBodyFontSizeBase?: number;
  fontPrimary?: string;
}

export interface IBnProviderSettings {
  colors?: {
    [key in EBrandingColorsOptions]?: string;
  };
  typography?: ITypography;
  fetchPermissios?:() => Promise<{data:any[]}>
}

export type TButton = 'primary' | 'secondary' | 'invert' | 'basic';

export type TSize = 'L' | 'M' | 'S' | 'XS';

export type IIcons =
  | 'remove'
  | 'minus'
  | 'profile_filled'
  | 'trash'
  | 'dollar'
  | 'info'
  | 'arrow_down'
  | 'edit_outline'
  | 'credit_card'
  | 'view'
  | 'more'
  | 'search'
  | 'arrow_up'
  | 'note_filled'
  | 'conflict'
  | 'close'
  | 'whistle'
  | 'whitecheck'
  | 'arrow_left'
  | 'mountain'
  | 'back'
  | 'forward'
  | 'calendar'
  | 'court'
  | 'backpack'
  | 'activity'
  | 'location'
  | 'tag'
  | 'profiles'
  | 'tickets'
  | 'cash'
  | 'reports'
  | 'clock'
  | 'settings_outline'
  | 'bell'
  | 'collapse'
  | 'support'
  | 'plus'
  | 'right-action'
  | 'excel';

export enum Icons {
  dropdown = 'dropdown',
  dropdown_active = 'dropdown_active',
  back = 'back',
  forward = 'forward',
  replace = 'replace',
  arrow_left = 'arrow_left',
  arrow_right = 'arrow_right',
  sort = 'sort',
  more = 'more',
  edit_outline = 'edit_outline',
  edit_filled = 'edit_filled',
  delete_outline = 'delete_outline',
  delete_filled = 'delete_filled',
  remove = 'remove',
  search = 'search',
  close = 'close',
  view = 'view',
  drag = 'drag',
  send = 'send',
  attach = 'attach',
  calendar = 'calendar',
  clock = 'clock',
  dollar = 'dollar',
  location_filled = 'location_filled',
  settings = 'settings',
  profile_filled = 'profile_filled',
  support = 'support',
  check = 'check',
  info = 'info',
  conflict = 'conflict',
  minus = 'minus',
  plus = 'plus',
  download = 'download',
  upload = 'upload',
  browse = 'browse',
  logout = 'logout',
  excel = 'excel',
  image = 'image',
  note_filled = 'note_filled',
  credit_card = 'credit_card',
  cash = 'cash',
  check_p = 'check_p',
  bill_account = 'bill_account',
  wallet = 'wallet',
  bank = 'bank',
  external_link = 'external_link',
  email = 'email',
  arrow_top = 'arrow_top',
  arrow_bottom = 'arrow_bottom',
  home = 'home',
  dashboard = 'dashboard',
  profiles = 'profiles',
  settings_outline = 'settings_outline',
  expand_all = 'expand_all',
  collaps_all = 'collaps_all',
  filters = 'filters',
  court = 'court',
  activity = 'activity',
  tag = 'tag',
  ticket = 'ticket',
  reports = 'reports',
  settings_outline2 = 'settings_outline2',
  bell = 'bell',
  collaps = 'collaps',
  percent = 'percent',
  expand = 'expand',
  globe_filled = 'globe_filled',
  lock_filled = 'lock_filled',
  activity_w = 'activity_w',
  question_mark = 'question_mark',
  tools = 'tools',
  cart = 'cart',
  discount = 'discount',
  add_ons = 'add_ons',
  vartical = 'vartical',
  horizontal = 'horizontal',
  list = 'list',
  note = 'note',
  location = 'location',
  whistle = 'whistle',
  exclamation_mark = 'exclamation_mark',
  maintenance = 'maintenance',
  clipboard = 'clipboard',
  copy = 'copy',
  flag = 'flag',
  intercom = 'intercom',

  g_male = 'g_male',
  g_female = 'g_female',
  g_coord = 'g_coord',
  g_boy='g_boy',
  g_girl='g_girl',
  g_boyAndGirl = 'g_boyAndGirl',
  pm_visa = 'pm_visa',
  pm_diners = 'pm_diners',
  pm_mastercard = 'pm_mastercard',
  pm_amex = 'pm_amex',
  pos_food = 'pos_food',
  pos_activity = 'pos_activity',
  pos_apparel = 'pos_apparel',
  pos_camp = 'pos_camp',
  pos_class = 'pos_class',
  pos_drink = 'pos_drink',
  pos_equipment = 'pos_equipment',
  act_activity = 'act_activity',
  act_camps = 'act_camps',
  act_classes = 'act_classes',
  act_clinics = 'act_clinics',
  act_clubTeams = 'act_clubTeams',
  act_leagues = 'act_leagues',
  act_privateLessons = 'act_privateLessons',
  act_tournaments = 'act_tournaments',
  hide = 'hide',
  pro_camp='pro_camp',
  pro_class='pro_class',
  pro_clinic='pro_clinic',
  pro_club_team='pro_club_team',
  pro_league='pro_league',
  pro_private_lesson='pro_private_lesson',
  pro_tournament='pro_tournament',
  s_adventure = 's_adventure',
s_badminton = 's_badminton',
s_baseball = 's_baseball',
s_basketball = 's_basketball',
s_blitzball = 's_blitzball',
s_bocceball = 's_bocceball',
s_bowling = 's_bowling',
s_boxing = 's_boxing',
s_broomball = 's_broomball',
s_catchball = 's_catchball',
s_cornhole = 's_cornhole',
s_cricket = 's_cricket',
s_crossfit = 's_crossfit',
s_cycling = 's_cycling',
s_dance = 's_dance',
s_dodgeball = 's_dodgeball',
s_fieldhockey = 's_fieldhockey',
s_fitness = 's_fitness',
s_football = 's_football',
s_frisbee = 's_frisbee',
s_gold = 's_gold',
s_handball = 's_handball',
s_hockey = 's_hockey',
s_kickball = 's_kickball',
s_kickboxing = 's_kickboxing',
s_martialArts = 's_martialArts',
s_other = 's_other',
s_outdoor = 's_outdoor',
s_pilatis = 's_pilatis',
s_pingpong = 's_pingpong',
s_raquetball = 's_raquetball',
s_rollerderby = 's_rollerderby',
s_rowing = 's_rowing',
s_rugby = 's_rugby',
s_runnning = 's_runnning',
s_sailing = 's_sailing',
s_skating = 's_skating',
s_skeeball = 's_skeeball',
s_skiing = 's_skiing',
s_snowboarding = 's_snowboarding',
s_soccer = 's_soccer',
s_softball = 's_softball',
s_spinning = 's_spinning',
s_squash = 's_squash',
s_sup = 's_sup',
s_surfing = 's_surfing',
s_swiming = 's_swiming',
s_tennis = 's_tennis',
s_triathlon = 's_triathlon',
s_vollyball = 's_vollyball',
s_wiffleball = 's_wiffleball',
s_windsurfing = 's_windsurfing',
s_yoga = 's_yoga',
corner_right_down='corner_right_down',
corner_right_up='corner_right_up',
corner_down_right='corner_down_right'
}

export enum ETypography {
  h1 = 'h1',
  h2 = 'h2',
  h3 = 'h3',
  h4 = 'h4',
  body1Accented = 'Body1Accented',
  body1 = 'body1',
  body1Link = 'body1Link',
  cta1 = 'cta1',
  body2Accented = 'body2Accented',
  body2 = 'body2',
  body2Link = 'body2Link',
  cta2 = 'cta2',
  captionAccented = 'captionAccented',
  caption = 'caption',
  captionLink = 'captionLink',
  captionCTA = 'captionCTA',
  overlineAccented = 'overlineAccented',
  overline = 'overline',
  overlineLink = 'overlineLink',
}

export enum ETypographyColor {
  primary = 'primary',
  secondary = 'secondary',
  teriaty = 'teriaty',
  disabled = 'disabled',
  white = 'white',
  black = 'black'
}
