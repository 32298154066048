import styled from '@emotion/styled';
import { IColors } from '../../types/theme';
import { setOpacity } from '../../styles/utils';

export const StyledSelect = styled.div(
  ({ theme: colors }: { theme: IColors }) => `
    display: flex;
    background: ${colors.bg_background_fields};
    align-items: center;
    border-radius: 2px;
    margin: 0;
    color: ${colors.tx_text_primary};
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 130%;
    flex-grow: 1;
    justify-content:space-between;
    outline:none;
    &.placeholder {
      color: ${colors.tx_text_teriaty};
    }

    .value{
        padding: 9px 12px;
        border: 1px solid transparent ;
        border-radius:2px 0 0 2px;
        width:100%;
        min-height:18px;
        cursor:default;
        box-sizing: unset;   
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        label{
          cursor:default;
        }
        text-align:left;
        &.focus{
          border: 1px solid ${colors.bg_border_primary} ;
        }
        &:hover, &.hover{
          
          border: 1px solid ${colors.bg_border_primary} ;
        }
        &.error{
          border: 1px solid ${colors.bg_error} ;
        }
    }
    .button{
        background:${colors.bg_text_teriaty};
        cursor:pointer;
        padding: 9px;
        display: flex;
        border-radius: 0 2px 2px 0;
        align-items: center;
        svg{

          color: ${colors.tx_white} !important;
        }
    }
    &.disabled{
      .button{
        background:${colors.bg_text_disabled};
      }
    }
  `
);
