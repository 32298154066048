import styled from '@emotion/styled';
import { IColors } from '../../types/theme';
import { setOpacity } from '../../styles/utils';

export const StyledSegment = styled.div(
  ({
    theme: colors,
    isPressed,
    width,
  }: {
    theme: IColors;
    isPressed: boolean;
    width: number;
  }) => `
  div{
    position: relative;
    width: min-content;
  }
  .background{
    display: block;
    padding:4px;
    background: ${colors.bg_background_fields};
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content:space-between;
  }
  

  .label{
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: ${colors.tx_text_secondary};
    display: block;
    position: relative;
    width: max-content;
    border-radius: 100px;
    cursor: pointer;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
    padding: 5.5px 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover{
      color:${colors.tx_text_primary};
    }
  }
  .toggle{
    display: block;
    position: absolute;
    border-radius: 100px;
    cursor: pointer;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
    padding: 5.5px 12px;
    color:white;
    background: ${colors.bg_text_primary};
    // transform:${isPressed ? 'translate(88%, 0)' : 'translate(0%, 0)'};
    margin-left:${isPressed ? width + 'px' : '0'};
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: ${colors.tx_white};
  }
  `
);
