import styled from '@emotion/styled';
import { ETypographyColor, IColors } from '../../types/theme';
import { setOpacity } from '../../styles/utils';

export const StyledH1 = styled.h1(
  ({ theme: colors, color }: { theme: IColors; color: ETypographyColor }) => `
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 59px;
  margin:0;
  color:${
    color === 'primary'
      ? colors.tx_text_primary
      : color === 'secondary'
      ? colors.tx_text_secondary
      : color === 'teriaty'
      ? colors.tx_text_teriaty
      : color === 'white'
      ? colors.tx_white
      : color === 'black'
      ? colors.black
      : colors.tx_text_disabled
  }
  `
);

export const StyledH2 = styled.h2(
  ({ theme: colors, color }: { theme: IColors; color: ETypographyColor }) => `
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 44px;
    margin:0;
    color:${
      color === 'primary'
        ? colors.tx_text_primary
        : color === 'secondary'
        ? colors.tx_text_secondary
        : color === 'teriaty'
        ? colors.tx_text_teriaty
        : color === 'white'
        ? colors.tx_white
        : color === 'black'
        ? colors.black
        : colors.tx_text_disabled
    }
    `
);

export const StyledH3 = styled.h3(
  ({ theme: colors, color }: { theme: IColors; color: ETypographyColor }) => `
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    margin:0;
    color:${
      color === 'primary'
        ? colors.tx_text_primary
        : color === 'secondary'
        ? colors.tx_text_secondary
        : color === 'teriaty'
        ? colors.tx_text_teriaty
        : color === 'white'
        ? colors.tx_white
        : color === 'black'
        ? colors.black
        : colors.tx_text_disabled
    }
    `
);

export const StyledH4 = styled.h4(
  ({ theme: colors, color }: { theme: IColors; color: ETypographyColor }) => `
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    margin:0;
    color:${
      color === 'primary'
        ? colors.tx_text_primary
        : color === 'secondary'
        ? colors.tx_text_secondary
        : color === 'teriaty'
        ? colors.tx_text_teriaty
        : color === 'white'
        ? colors.tx_white
        : color === 'black'
        ? colors.black
        : colors.tx_text_disabled
    }
    `
);

export const StyledBodyAccented = styled.div(
  ({ theme: colors, color }: { theme: IColors; color: ETypographyColor }) => `
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color:${
      color === 'primary'
        ? colors.tx_text_primary
        : color === 'secondary'
        ? colors.tx_text_secondary
        : color === 'teriaty'
        ? colors.tx_text_teriaty
        : color === 'white'
        ? colors.tx_white
        : color === 'black'
        ? colors.black
        : colors.tx_text_disabled
    }
    `
);

export const StyledBody1 = styled.div(
  ({ theme: colors, color }: { theme: IColors; color: ETypographyColor }) => `
    font-family: Montserrat;
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 20px;
color:${
    color === 'primary'
      ? colors.tx_text_primary
      : color === 'secondary'
      ? colors.tx_text_secondary
      : color === 'teriaty'
      ? colors.tx_text_teriaty
      : color === 'white'
      ? colors.tx_white
      : color === 'black'
      ? colors.black
      : colors.tx_text_disabled
  }
    `
);

export const StyledBody1Link = styled.div(
  ({ theme: colors, color }: { theme: IColors; color: ETypographyColor }) => `
    font-family: Montserrat;
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 20px;
text-decoration-line: underline;
color:${
    color === 'primary'
      ? colors.tx_text_primary
      : color === 'secondary'
      ? colors.tx_text_secondary
      : color === 'teriaty'
      ? colors.tx_text_teriaty
      : color === 'white'
      ? colors.tx_white
      : color === 'black'
      ? colors.black
      : colors.tx_text_disabled
  }
    `
);

export const StyledCTA = styled.div(
  ({ theme: colors, color }: { theme: IColors; color: ETypographyColor }) => `
    font-family: Montserrat;
font-style: normal;
font-weight: bold;
font-size: 16px;
line-height: 20px;
text-transform: uppercase;
color:${
    color === 'primary'
      ? colors.tx_text_primary
      : color === 'secondary'
      ? colors.tx_text_secondary
      : color === 'teriaty'
      ? colors.tx_text_teriaty
      : color === 'white'
      ? colors.tx_white
      : color === 'black'
      ? colors.black
      : colors.tx_text_disabled
  }
    `
);

export const StyledBody2Accented = styled.div(
  ({ theme: colors, color }: { theme: IColors; color: ETypographyColor }) => `
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color:${
      color === 'primary'
        ? colors.tx_text_primary
        : color === 'secondary'
        ? colors.tx_text_secondary
        : color === 'teriaty'
        ? colors.tx_text_teriaty
        : color === 'white'
        ? colors.tx_white
        : color === 'black'
        ? colors.black
        : colors.tx_text_disabled
    }
`
);

export const StyledBody2 = styled.div(
  ({ theme: colors, color }: { theme: IColors; color: ETypographyColor }) => `
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 130%;
    color:${
      color === 'primary'
        ? colors.tx_text_primary
        : color === 'secondary'
        ? colors.tx_text_secondary
        : color === 'teriaty'
        ? colors.tx_text_teriaty
        : color === 'white'
        ? colors.tx_white
        : color === 'black'
        ? colors.black
        : colors.tx_text_disabled
    }
`
);

export const StyledBody2Link = styled.div(
  ({ theme: colors, color }: { theme: IColors; color: ETypographyColor }) => `
font-family: Montserrat;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 17px;
text-decoration-line: underline;
color:${
    color === 'primary'
      ? colors.tx_text_primary
      : color === 'secondary'
      ? colors.tx_text_secondary
      : color === 'teriaty'
      ? colors.tx_text_teriaty
      : color === 'white'
      ? colors.tx_white
      : color === 'black'
      ? colors.black
      : colors.tx_text_disabled
  }
`
);

export const StyledCTA2 = styled.div(
  ({ theme: colors, color }: { theme: IColors; color: ETypographyColor }) => `
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    color:${
      color === 'primary'
        ? colors.tx_text_primary
        : color === 'secondary'
        ? colors.tx_text_secondary
        : color === 'teriaty'
        ? colors.tx_text_teriaty
        : color === 'white'
        ? colors.tx_white
        : color === 'black'
        ? colors.black
        : colors.tx_text_disabled
    }
`
);

export const StyledCaptionAccented = styled.div(
  ({ theme: colors, color }: { theme: IColors; color: ETypographyColor }) => `
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color:${
      color === 'primary'
        ? colors.tx_text_primary
        : color === 'secondary'
        ? colors.tx_text_secondary
        : color === 'teriaty'
        ? colors.tx_text_teriaty
        : color === 'white'
        ? colors.tx_white
        : color === 'black'
        ? colors.black
        : colors.tx_text_disabled
    }
`
);

export const StyledCaption = styled.div(
  ({ theme: colors, color }: { theme: IColors; color: ETypographyColor }) => `
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    color:${
      color === 'primary'
        ? colors.tx_text_primary
        : color === 'secondary'
        ? colors.tx_text_secondary
        : color === 'teriaty'
        ? colors.tx_text_teriaty
        : color === 'white'
        ? colors.tx_white
        : color === 'black'
        ? colors.black
        : colors.tx_text_disabled
    }
`
);

export const StyledCaptionLink = styled.div(
  ({ theme: colors, color }: { theme: IColors; color: ETypographyColor }) => `
    font-family: Montserrat;
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 15px;
text-decoration-line: underline;
color:${
    color === 'primary'
      ? colors.tx_text_primary
      : color === 'secondary'
      ? colors.tx_text_secondary
      : color === 'teriaty'
      ? colors.tx_text_teriaty
      : color === 'white'
      ? colors.tx_white
      : color === 'black'
      ? colors.black
      : colors.tx_text_disabled
  }

    `
);

export const StyledCaptionCTA = styled.div(
  ({ theme: colors, color }: { theme: IColors; color: ETypographyColor }) => `
font-family: Montserrat;
font-style: normal;
font-weight: bold;
font-size: 12px;
line-height: 15px;
text-transform: uppercase;
color:${
    color === 'primary'
      ? colors.tx_text_primary
      : color === 'secondary'
      ? colors.tx_text_secondary
      : color === 'teriaty'
      ? colors.tx_text_teriaty
      : color === 'white'
      ? colors.tx_white
      : color === 'black'
      ? colors.black
      : colors.tx_text_disabled
  }
`
);

export const StyledOverlinedAccented = styled.div(
  ({ theme: colors, color }: { theme: IColors; color: ETypographyColor }) => `
    font-family: Montserrat;
font-style: normal;
font-weight: 600;
font-size: 10px;
line-height: 12px;
text-transform: capitalize;
color:${
    color === 'primary'
      ? colors.tx_text_primary
      : color === 'secondary'
      ? colors.tx_text_secondary
      : color === 'teriaty'
      ? colors.tx_text_teriaty
      : color === 'white'
      ? colors.tx_white
      : color === 'black'
      ? colors.black
      : colors.tx_text_disabled
  }
    `
);

export const StyledOverline = styled.div(
  ({ theme: colors, color }: { theme: IColors; color: ETypographyColor }) => `
    font-family: Montserrat;
font-style: normal;
font-weight: normal;
font-size: 10px;
line-height: 12px;
color:${
    color === 'primary'
      ? colors.tx_text_primary
      : color === 'secondary'
      ? colors.tx_text_secondary
      : color === 'teriaty'
      ? colors.tx_text_teriaty
      : color === 'white'
      ? colors.tx_white
      : color === 'black'
      ? colors.black
      : colors.tx_text_disabled
  }
`
);

export const StyledOverlineLink = styled.div(
  ({ theme: colors, color }: { theme: IColors; color: ETypographyColor }) => `
    font-family: Montserrat;
font-style: normal;
font-weight: 500;
font-size: 10px;
line-height: 12px;
text-decoration-line: underline;
color:${
    color === 'primary'
      ? colors.tx_text_primary
      : color === 'secondary'
      ? colors.tx_text_secondary
      : color === 'teriaty'
      ? colors.tx_text_teriaty
      : color === 'white'
      ? colors.tx_white
      : color === 'black'
      ? colors.black
      : colors.tx_text_disabled
  }
`
);
